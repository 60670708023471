import { Cinema } from "../cinema-module/cinema";
import { StorefrontCinema } from "./storefront-cinema";
import { getFeatureOrder } from "./helper";
import { Countdown } from "@ft9/countdown";

const cinema = new Cinema(new Countdown());

const benefitCinemas = document.getElementById("ft9_benefit_cinema__root_marker");
cinema.init(getFeatureOrder(benefitCinemas));

const storefrontCinema = new StorefrontCinema();
storefrontCinema.init();
